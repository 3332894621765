import React from 'react';
import { Modal } from 'semantic-ui-react';
import tw, { styled, css } from 'twin.macro';
import { Global } from '@emotion/react';

import Icon from '~modules/common/icons';
import { zIndex } from '~common/style.helpers';
import 'semantic-ui-css/components/modal.css';
import { BodyScrollLock } from '~modules/common/components';

const ZindexFix = () => (
    <Global
        styles={css`
            .ui.modals.active {
                z-index: ${zIndex('MODALS')};
            }
        `}
    />
);

const StyledModal = styled(Modal, {
    shouldForwardProp: (prop) =>
        !['hasInnerClose', 'hasOuterClose'].includes(prop),
})`
    &.ui.modal {
        ${tw`antialiased`}
    }
    &.ui.large.modal {
        height: 85vh;
        @media only screen and (min-width: 768px) {
            max-width: 90vw;
        }
    }
    &.ui.tiny.modal {
        ${tw`sm:w-auto`}
        max-width: 600px;
    }

    // Place above the loader on small screens
    &.ui.modal > .close {
        z-index: 1001;
    }

    ${({ hasInnerClose }) =>
        hasInnerClose &&
        css`
            &.ui.modal > .close {
                top: 1.5rem;
                right: 0.75rem;
                ${tw`p-0 m-0 text-icon hocus:text-icon-dark text-base`};
                /* Override semantic media query */
                @media only screen and (max-width: 767px) {
                    top: 1.5rem !important;
                    right: 1.1rem !important;
                }
            }

            i.icon.close:before {
                content: '';
                top: 0;
                right: 0;
            }
        `}
    ${({ hasOuterClose }) =>
        hasOuterClose &&
        css`
            &.ui.modal > .close {
                ${tw`flex items-center justify-center`}
                /* Override semantic media queries */
                @media only screen and (max-width: 991px) {
                    ${tw`text-white !top-[-2.75rem] !right-[-0.25rem]`}
                }
            }
        `}
`;

const Content = styled.div`
    ${tw`relative block w-full h-full leading-normal`}
    > * {
        ${tw`rounded overflow-hidden`}
    }
    a:not(.button-external) {
        ${tw`text-primary hocus:(text-primary-dark underline)`}
    }
    ${({ hasPadding }) => hasPadding && tw`p-2`}
`;

const CloseIcon = ({ onClick }) => (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="close" {...{ onClick }}>
        <Icon name="close" />
    </div>
);

export default ({
    children,
    closeIcon,
    hasInnerClose,
    hasOuterClose,
    hasPadding,
    ...rest
}) => (
    <StyledModal
        {...(closeIcon && { closeIcon: <CloseIcon /> })}
        {...{ hasInnerClose, hasOuterClose }}
        {...rest}
    >
        <BodyScrollLock namespace="ModalSimple" />
        <ZindexFix />
        <Content {...{ hasPadding }}>{children}</Content>
    </StyledModal>
);
