import { useLayoutEffect } from 'react';

const scrollLockStates = [];

const BodyScrollLock = ({ namespace }) => {
    // console.log(`BodyScrollLock render in ${namespace}`, {
    //     overflowState: window.getComputedStyle(document.body).overflow,
    //     scrollLockStates,
    // });

    useLayoutEffect(() => {
        scrollLockStates.push(window.getComputedStyle(document.body).overflow);
        // console.log(`BodyScrollLock useLayoutEffect mounting in ${namespace}`, {
        //     overflowState: window.getComputedStyle(document.body).overflow,
        //     scrollLockStates,
        // });

        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = scrollLockStates.pop();

            // console.log(
            //     `BodyScrollLock useLayoutEffect cleanup in ${namespace}`,
            //     {
            //         overflowState: window.getComputedStyle(document.body)
            //             .overflow,
            //         scrollLockStates,
            //     }
            // );
        };
    }, [namespace]);
};

export default BodyScrollLock;
